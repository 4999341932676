import React from "react";

import { Card } from "./card/Card";
import "../cards/card/card.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const Cards = () => {
  const { t } = useTranslation();
  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("KingsNordic.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "KingsNordic.pdf";
        alink.click();
      });
    });
  };

  return (
    <div className="container" id="smaker">
      <div className="text-center mt-5">
        <h1 className="big-text text-center">{t("15-kunglig")}</h1>
      </div>
      <p className="text-center">{t("15-kunglig-text")}</p>

      <div className="row">
        <Card
          imgUrl={"https://i.postimg.cc/W1197ZqZ/testing-Page-064.png"}
          title={"VATTENMELON (LUCH ICE)"}
          descripton={t("lush-ice")}
          cssStyle={"card-title text-center lush"}
          cssStyleDesc={"card-text mb-4 lush"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/Y9vZzs0T/testing-Page-040.png"}
          title={"BLUEBERRY RASPBERRY"}
          descripton={t("blueberry-raspberry")}
          cssStyle={"card-title text-center blue-rasp"}
          cssStyleDesc={"card-text mb-4 blue-rasp"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/ZK45pHnY/VOOM-mesh-1155.png"}
          title={"MANGO ICE"}
          descripton={t("mango")}
          cssStyle={"card-title text-center mango"}
          cssStyleDesc={"card-text mb-4 mango"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/8cFs3z8p/VOOM-mesh-1151.png"}
          title={"apple-ice"}
          descripton={t("apple-ice")}
          cssStyle={"card-title text-center apple-ice"}
          cssStyleDesc={"card-text mb-4 apple-ice"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/4dQCdFLc/testing-Page-060.png"}
          title={"STRAWBERRY ICE CREAM"}
          descripton={t("STRAWBERRY-ICE-CREAM")}
          cssStyle={"card-title text-center STRAWBERRY-ICE-CREAM"}
          cssStyleDesc={"card-text mb-4 STRAWBERRY-ICE-CREAM"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/5NWmhnSM/VOOM-mesh-1164.png"}
          title={"COOL MINT"}
          descripton={t("cool-mint")}
          cssStyle={"card-title text-center mint"}
          cssStyleDesc={"card-text mb-4 mint"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/C1MRCVwN/VOOM-mesh-1168.png"}
          title={"STRAWBERRY APPLE"}
          descripton={t("STRAWBERRY-APPLE")}
          cssStyle={"card-title text-center STRAWBERRY-APPLE"}
          cssStyleDesc={"card-text mb-4 STRAWBERRY-APPLE"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/9fTH3fyJ/testing-Page-048.png"}
          title={"GUAVA ICE"}
          descripton={t("guava-ice")}
          cssStyle={"card-title text-center guava"}
          cssStyleDesc={"card-text mb-4 guava"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/bNqg2Xj6/VOOM-mesh-1143.png"}
          title={"GRAPE PARADISE"}
          descripton={t("GRAPE-PARADISE")}
          cssStyle={"card-title text-center GRAPE-PARADISE"}
          cssStyleDesc={"card-text mb-4 GRAPE-PARADISE"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/mgPGwjkx/testing-Page-052.png"}
          title={"COLA ICE"}
          descripton={t("ice-cola")}
          cssStyle={"card-title text-center cola"}
          cssStyleDesc={"card-text mb-4 cola"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/QMNwhBfq/VOOM-mesh-1147.png"}
          title={"BLOOD ORANGE"}
          descripton={t("BLOOD-ORANGE")}
          cssStyle={"card-title text-center blood-orange"}
          cssStyleDesc={"card-text mb-4 blood-orange"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/MT04q6zw/VOOM-mesh-1172.png"}
          title={"COCKTAIL"}
          descripton={t("cocktail")}
          cssStyle={"card-title text-center cocktail"}
          cssStyleDesc={"card-text mb-4 cocktail"}
        />

        <Card
          imgUrl={"https://i.postimg.cc/50VdfM31/testing-Page-056.png"}
          title={"PEACH ICE"}
          descripton={t("peach-ice")}
          cssStyle={"card-title text-center peach-ice"}
          cssStyleDesc={"card-text mb-4 peach-ice"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/mk7PtRxw/testing-Page-044.png"}
          title={"PURE TOBACCO"}
          descripton={t("pure-tobacco")}
          cssStyle={"card-title text-center tobacco"}
          cssStyleDesc={"card-text mb-4 tobacco"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/7L0LSW36/VOOM-mesh-1160.png"}
          title={"STRAWBERRY LYCHEE"}
          descripton={t("STRAWBERRY-LYCHEE")}
          cssStyle={"card-title text-center strawberry-ly"}
          cssStyleDesc={"card-text mb-4 strawberry-ly"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/BvQdX60S/testing-Page-036.png"}
          title={"LOVE 66"}
          descripton={t("love-66")}
          cssStyle={"card-title text-center love66"}
          cssStyleDesc={"card-text mb-4 love66"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/mrXKMsLQ/testing-Page-032.png"}
          title={"ENERGY-DRINK"}
          descripton={t("ENERGY-DRINK")}
          cssStyle={"card-title text-center ENERGY-DRINK"}
          cssStyleDesc={"card-text mb-4 ENERGY-DRINK"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/L65fMxVQ/VOOM-mesh-1176.png"}
          title={"MIX BERRY"}
          descripton={t("mix-berry")}
          cssStyle={"card-title text-center mix-berry"}
          cssStyleDesc={"card-text mb-4 mix-berry"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/sXb864Zx/testing-Page-028.png"}
          title={"BRAZIL MINT"}
          descripton={t("BRAZIL MINT")}
          cssStyle={"card-title text-center brazil-mint"}
          cssStyleDesc={"card-text mb-4 brazil-mint"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/RhR3zH3B/VOOM-mesh-1180.png"}
          title={"CUBAN TOBACCO"}
          descripton={t("cuban-tobacco")}
          cssStyle={"card-title text-center cuban"}
          cssStyleDesc={"card-text mb-4 cuban"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/YqQcPZRH/testing-Page-024.png"}
          title={"MENTHOL MOJITO"}
          descripton={t("MENTHOL-MOJITO")}
          cssStyle={"card-title text-center MENTHOL-MOJITO"}
          cssStyleDesc={"card-text mb-4 MENTHOL-MOJITO"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/BZNx4nvZ/testing-Page-016.png"}
          title={"PINEAPPLE LEMONADE"}
          descripton={t("pineapple-lemonade")}
          cssStyle={"card-title text-center pineapple-lemonade"}
          cssStyleDesc={"card-text mb-4 pineapple-lemonade"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/N05g9b6P/testing-Page-020.png"}
          title={"HONEY MELON"}
          descripton={t("honey-melon")}
          cssStyle={"card-title text-center honey-melon"}
          cssStyleDesc={"card-text mb-4 honey-melon"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/zfkbJxkb/testing-Page-004.png"}
          title={"PINK LEMOADE"}
          descripton={t("pink-lemonade")}
          cssStyle={"card-title text-center pink-lemonade"}
          cssStyleDesc={"card-text mb-4 pink-lemonade"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/WbCTk049/testing-Page-012.png"}
          title={"STRAWBERRY WATERMELON BUBBLEGUM"}
          descripton={t("strawberry-watermelon-gum")}
          cssStyle={"card-title text-center strawberry-watermelon-gum"}
          cssStyleDesc={"card-text mb-4 strawberry-watermelon-gum"}
        />
        <Card
          imgUrl={"https://i.postimg.cc/L5J3Ycy0/testing-Page-008.png"}
          title={"STRAWBERRY NANA"}
          descripton={t("strawberry-nana")}
          cssStyle={"card-title text-center mango"}
          cssStyleDesc={"card-text mb-4 mango"}
        />
      </div>

      {/*   <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4 d-flex align-items-stretch">
          <div className="card geeks">
            <img
              src="https://i.postimg.cc/Wp0GBKXd/16-LOVE-66-1.png"
              className="card-img-top"
              alt="..."
            />
            <h5 className="card-title text-center love66">LOLLO (LOVE 66)</h5>
            <div className="card-body d-flex flex-column">
              <p className="card-text mb-4 love66">{t("love-66")}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div> */}

      <h3 className="big-text text-center mt-3">{t("download-pdf")}</h3>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8 text-center">
          <button
            onClick={onButtonClick}
            type="button"
            className="btn btn-dark col-md-6"
          >
            {t("download")}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-filetype-pdf"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
              />
            </svg>
          </button>
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div
            id="regularHeadingFirst"
            className="read-more-button text-center"
          >
            <a
              className="collapse-btn"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {t("spec")}
            </a>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="collapse mt-5" id="collapseExample">
        <div className="row">
          <div className="title_lines text-center spec-ing"></div>
          <div className="col-md-6 mt-3">
            <h1 className="big-text text-center">
              Nordic Kings Engångs E-Cigg specifikation
            </h1>
            <div className="mt-3 text-center">
              <p>
                <span className="taste-span">Nikotin: </span>20mg salt
              </p>
              <p>
                <span className="taste-span">E-juice kapacitet: </span>2ml
              </p>
              <p>
                <span className="taste-span">Motstånd: </span>1.8ohm
              </p>
              <p>
                <span className="taste-span">Spänning: </span>3.7V
              </p>
              <p>
                <span className="taste-span">Vikt: </span>20g
              </p>
              <p>
                <span className="taste-span">E-juice mix: </span>50/50 PG/VG
              </p>
              <p>
                <span className="taste-span">Puffar: </span> Aprox 800
              </p>
              <p>
                <span className="taste-span">Mesh coil</span>
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <h1 className="big-text text-center">Ingredienser</h1>

            <div className="mt-3 text-center">
              <p>Vegetabilisk</p>
              <p>Glycerin</p>
              <p>Propylene glycol</p>
              <p>Nikotin</p>
              <p>Nensoesyra</p>
              <p>Smakämnen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
