import React from "react";

import "../tastes/taste/taste.css";

export const OurTaste = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-5">
          <h1 className="big-text text-center">
            Utforska våra premiumvapes som finns i 26 olika smaker!
          </h1>
          <div className="row">
            <div className="col-md-6 mt-3 text-center">
              <ul className="list-group">
                <a
                  type="button"
                  className="lushIce"
                  data-bs-toggle="modal"
                  data-bs-target="#lushIce"
                >
                  <li className="list-group-item">Vattenmelon (Lush Ice)</li>
                </a>

                <a
                  type="button"
                  className="mango"
                  data-bs-toggle="modal"
                  data-bs-target="#iceMango"
                >
                  <li className="list-group-item">Mango ICE</li>
                </a>

                <a
                  type="button"
                  className="grape-straw"
                  data-bs-toggle="modal"
                  data-bs-target="#iceCream"
                >
                  <li className="list-group-item">Strawberry ice cream</li>
                </a>
                <a
                  type="button"
                  className="strawApple"
                  data-bs-toggle="modal"
                  data-bs-target="#strawApple"
                >
                  <li className="list-group-item">Strawberry Apple</li>
                </a>
                <a
                  type="button"
                  className="grapeParadise"
                  data-bs-toggle="modal"
                  data-bs-target="#grapeParadise"
                >
                  <li className="list-group-item">Grape Paradise</li>
                </a>
                <a
                  type="button"
                  className="bloodOrange"
                  data-bs-toggle="modal"
                  data-bs-target="#bloodOrange"
                >
                  <li className="list-group-item">Blood Orange</li>
                </a>
                <a
                  type="button"
                  className="peach-ice"
                  data-bs-toggle="modal"
                  data-bs-target="#peachIce"
                >
                  <li className="list-group-item">Peach Ice</li>
                </a>
                <a
                  type="button"
                  className="strawberryLy"
                  data-bs-toggle="modal"
                  data-bs-target="#strawberryLy"
                >
                  <li className="list-group-item">Strawberry Lychee</li>
                </a>
                <a
                  type="button"
                  className="energy"
                  data-bs-toggle="modal"
                  data-bs-target="#energy"
                >
                  <li className="list-group-item">ENERGY DRINK</li>
                </a>
                <a
                  type="button"
                  className="brazil"
                  data-bs-toggle="modal"
                  data-bs-target="#brazil"
                >
                  <li className="list-group-item">Brazil mint</li>
                </a>
                <a
                  type="button"
                  className="honey"
                  data-bs-toggle="modal"
                  data-bs-target="#honey"
                >
                  <li className="list-group-item">honey melon</li>
                </a>
                <a
                  type="button"
                  className="mojito"
                  data-bs-toggle="modal"
                  data-bs-target="#mojito"
                >
                  <li className="list-group-item">MENTHOL MOJITO</li>
                </a>

                <a
                  type="button"
                  className="strawberryWB"
                  data-bs-toggle="modal"
                  data-bs-target="#strawberryWB"
                >
                  <li className="list-group-item">
                    strawberry WATERMELON BUBBLEGUM
                  </li>
                </a>
              </ul>
            </div>

            <div className="col-md-6 mt-3 text-center">
              <ul className="list-group">
                <a
                  type="button"
                  className="blueRasp"
                  data-bs-toggle="modal"
                  data-bs-target="#blueBerryRasp"
                >
                  <li className="list-group-item">Blueberry Raspberry</li>
                </a>

                <a
                  type="button"
                  className="appleIce"
                  data-bs-toggle="modal"
                  data-bs-target="#appleIce"
                >
                  <li className="list-group-item">Apple Ice</li>
                </a>
                <a
                  type="button"
                  className="coolMint"
                  data-bs-toggle="modal"
                  data-bs-target="#coolMint"
                >
                  <li className="list-group-item">Cool Mint</li>
                </a>
                <a
                  type="button"
                  className="guavaIce"
                  data-bs-toggle="modal"
                  data-bs-target="#guavaIce"
                >
                  <li className="list-group-item">Guava Ice</li>
                </a>
                <a
                  type="button"
                  className="iceCola"
                  data-bs-toggle="modal"
                  data-bs-target="#iceCola"
                >
                  <li className="list-group-item">Cola ICE</li>
                </a>
                <a
                  type="button"
                  className="cocktail"
                  data-bs-toggle="modal"
                  data-bs-target="#cocktail"
                >
                  <li className="list-group-item">Cocktail</li>
                </a>
                <a
                  type="button"
                  className="pureTobacco"
                  data-bs-toggle="modal"
                  data-bs-target="#pureTobacco"
                >
                  <li className="list-group-item">Pure Tobacco</li>
                </a>
                <a
                  type="button"
                  className="lollo"
                  data-bs-toggle="modal"
                  data-bs-target="#lollo"
                >
                  <li className="list-group-item">Lollo (Love 66)</li>
                </a>
                <a
                  type="button"
                  className="cuban"
                  data-bs-toggle="modal"
                  data-bs-target="#cuban"
                >
                  <li className="list-group-item">Cuban Tobacco</li>
                </a>
                <a
                  type="button"
                  className="mixBerry"
                  data-bs-toggle="modal"
                  data-bs-target="#mixBerry"
                >
                  <li className="list-group-item">Mix berry</li>
                </a>

                <a
                  type="button"
                  className="pineapple-lemonade"
                  data-bs-toggle="modal"
                  data-bs-target="#pineapple-lemonade"
                >
                  <li className="list-group-item">pineapple lemonade</li>
                </a>

                <a
                  type="button"
                  className="pinkLemon"
                  data-bs-toggle="modal"
                  data-bs-target="#pinkLemon"
                >
                  <li className="list-group-item">pink lemonade</li>
                </a>
                <a
                  type="button"
                  className="strawberryNana"
                  data-bs-toggle="modal"
                  data-bs-target="#strawberryNana"
                >
                  <li className="list-group-item">strawberry nana</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>

      <div className="modal fade" id="blueBerryRasp">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Blueberry Raspberry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/k58JpQND/VOOM-mesh-1211.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="iceMango">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Mango Ice</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/CK55rpZL/VOOM-mesh-1159.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="strawberryNana">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Strawberry NANA</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/Y0SGKRwN/VOOM-mesh-1244.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="pinkLemon">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Pink Lemonade</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/zGhLr34Q/VOOM-mesh-1248.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="iceCream">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Strawberry Ice Cream</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/Qx8YHjHM/VOOM-mesh-1191.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="mixBerry">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Mix berry</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/FFW3VvTz/VOOM-mesh-1179.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="energy">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Energy Drink</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/gjNCnjpv/VOOM-mesh-1220.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="mojito">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">MENTHOL MOJITO</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/4NMtG6Ms/VOOM-mesh-1228.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="peachIce">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Peach Ice</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/gjmf4R49/VOOM-mesh-1195.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="strawApple">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Strawberry Apple</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/TP5505H3/VOOM-mesh-1171.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="appleIce">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Apple Ice</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/3w9S76Fq/VOOM-mesh-1154.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="strawberryLy">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Strawberry Lychee</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/PqdYX56X/VOOM-mesh-1163.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="iceCola">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Cola ice</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/0Np6wRBt/VOOM-mesh-1199.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bloodOrange">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Blood Orange</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/0jCqqZr2/VOOM-mesh-1150.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="grapeParadise">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Grape Paradise</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/YCCttc6c/VOOM-mesh-1146.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="guavaIce">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Guava Ice</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/g2tsTRc9/VOOM-mesh-1203.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="allVapes">
        <div className="modal-dialog">
          <div>
            <img
              src="https://i.postimg.cc/8CZpz6f8/sticks-1.png"
              alt="bild"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="modal fade" id="lushIce">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">
                Vattenmelon (Lush Ice)
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/zGxQm7Yp/testing-Page-061.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="pureTobacco">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Pure Tobacco</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/tRdLmrLL/VOOM-mesh-1207.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="coolMint">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Cool Mint</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/wj2ZT76F/VOOM-mesh-1167.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="cocktail">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Cocktail</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/nLgJpCkg/VOOM-mesh-1175.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="lollo">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Lollo (Love 66)</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/V6fjskk9/VOOM-mesh-1216.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="brazil">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Brazil Mint</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/VNDJRBzM/VOOM-mesh-1224.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="honey">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">HONEY MELON</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/dVJVCDgg/VOOM-mesh-1232.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="pineapple-lemonade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">pineapple lemonade</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/br63RZ3L/VOOM-mesh-1236.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="strawberryWB">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">
                STRAWBERRY WATERMELON BUBBLEGUM
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/TwKMz54L/VOOM-mesh-1240.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="cuban">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">Cuban Tobacco</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src="https://i.postimg.cc/VkW4BTpN/VOOM-mesh-1183.png"
                alt="bild"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
